<header *ngIf="(selectedAppearanceVariantSubject |async) as selectedAppearanceVariant" class="flex flex-col gap-8"
  mat-dialog-title>
  <div class="flex items-center gap-2">
    <img priority class="w-16" ngSrcset="200w" sizes="200px" [height]="selectedAppearanceVariant.thumbNail.height"
      [width]="selectedAppearanceVariant.thumbNail.width" [ngSrc]="selectedAppearanceVariant.thumbNail.content" />
    <div class="flex flex-col">
      <p class="text-lg font-medium">{{ product.designation | titlecase }}</p>
      <span class="text-sm font-normal text-gray-800">
        {{ selectedAppearanceVariant.look.texture | titlecase }}
        {{ selectedAppearanceVariant.look.material | titlecase }}
      </span>
    </div>
  </div>
  <ul cdkListbox [cdkListboxValue]="[selectedAppearanceVariant]" (cdkListboxValueChange)="updateAppearance($event)"
    [cdkListboxCompareWith]="compareAppearance" *ngIf="product.appearanceVariants | arrayExists"
    class="flex items-center gap-3 self-center">
    <li [cdkOption]="item" [matBadge]="getVariantCount(item.id)" [matBadgeHidden]="getVariantCount(item.id) === 0"
      class="w-8 rounded-full border-2  border-white appearance-el"
      [class.border-orange-600]="(selectedAppearanceVariantSubject |async).id === item.id"
      *ngFor="let item of product.appearanceVariants">
      <img class="max-w-full rounded-full" ngSrcset="100w" sizes="100px" [height]="item.look.thumbNail.height"
        [width]="item.look.thumbNail.width" [ngSrc]="item.look.thumbNail.content" />
    </li>
  </ul>

  <h3 class="flex items-center justify-between p-2 bg-gray-100 rounded-md">
    <p class=" text-gray-800 first-letter:uppercase" i18n>total</p>
    <p>
      {{
      totalPrice$
      | async
      | currency : (currency$ | async).alphaCode : 'symbol-narrow' : '1.0-0'
      }}
    </p>
  </h3>
</header>
<main [formGroup]="quartForm" mat-dialog-content>
  <ul *ngIf="(selectedAppearanceVariantSubject |async) as selectedAppearanceVariant" class="flex flex-col gap-4">
    <li class="my2 py1 flex items-center justify-between" *ngFor="let variant of product.dimensionVariants">
      <div>
        <div>
          {{ variant.designation }} (
          {{
          (priceFactor$ |
          async)?.variantPrice(variant.id,(selectedAppearanceVariantSubject |async).id)
          | currency
          : (currency$ | async).alphaCode
          : 'symbol-narrow'
          : '1.0-0'
          }}
          / unit)
        </div>
        <div class="text text-gray-800 text-sm">
          {{ getDimensionString(variant) }}
        </div>
      </div>
      <app-counter [formControl]="getVariantControl(variant.id)"></app-counter>
    </li>
  </ul>
</main>

<div mat-dialog-actions class="flex items-center justify-between">
  <button mat-button mat-dialog-close><span i18n="cancel">cancel</span></button>
  <button i18n (click)="submit()" mat-button color="primary" type="submit" cdkFocusInitial>
    <span>add to cart</span>
  </button>
</div>