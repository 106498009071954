import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CartItem } from 'src/app/models/entities/quart-item.entity';

@Component({
  selector: 'app-cart-item-list',
  templateUrl: './cart-item-list.component.html',
  styleUrls: ['./cart-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemListComponent {
  @Input() cartItems: CartItem[];
  @Input() currencyCode: string;
  isSmallScreen = this.breakpointObserver.isMatched('(max-width: 320px)');

  constructor(private breakpointObserver: BreakpointObserver) {}
  currentPrice = (cartItem: CartItem): number =>
    (cartItem.current_unit_price ?? cartItem.order_unit_price) *
    cartItem.quantity;
}
