import { Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { AppDataService } from 'src/app/core/services/app-data.service';
import {
  Entity,
  EntityConfig,
  EntityFilter,
} from 'src/app/models/base/entity.base';
import { KeysMatching } from 'src/app/utils/utils';

export interface IEntityStore<T, A, F> {
  updateEntities(entity: T): void;
  deleteEntity(entity: T): void;
  entities: Observable<T[]>;
  entitiesCount: Observable<number>;
  upsert(payload: Partial<F>, entity?: T): Observable<T>;
}

export function upsertFn<T>(array: T[], el: T, compareProp: keyof T): T[] {
  const _array = [...array];
  const _index = _array.findIndex(
    (ent) => ent[compareProp] === el[compareProp]
  );
  if (_index >= 0) {
    _array.splice(_index, 1, el);
  } else {
    _array.push(el);
  }
  return _array;
}
export interface CrudConfig {
  notify: boolean;
}
export abstract class EntityStore<T extends Entity<T> = any, F = any, A = any>
  implements IEntityStore<T, A, F>
{
  private entitiesSubject = new BehaviorSubject<T[]>([]);
  private entitieCountSubject = new BehaviorSubject<number>(0);
  private selectedEntityIdSubject = new BehaviorSubject<number | undefined>(
    undefined
  );
  private filtersSubject = new BehaviorSubject<EntityFilter<T>[]>([]);
  entityConfig!: EntityConfig<T, F>;
  protected ads: AppDataService;
  public matSnackBar: MatSnackBar;
  constructor(injector: Injector) {
    this.ads = injector.get(AppDataService);
    this.matSnackBar = injector.get(MatSnackBar);
  }
  upsert(
    payload: Partial<F>,
    entity?: T,
    extraAction?: string,
    config?: CrudConfig
  ): Observable<T> {
    const update = entity !== undefined;
    const feature = this.entityConfig.feature;
    const baseUri = this.entityConfig.baseUri;
    const _payload = JSON.stringify(this.entityConfig.serializerFun(payload));
    const msg =
      (update
        ? this.entityConfig.messages?.action?.updated?.success
        : this.entityConfig.messages?.action?.created?.success) ??
      $localize`successfully ${update ? 'updated' : 'created'} ${feature}`;
    const extraActionUri = extraAction ? extraAction + '/' : '';
    const uri =
      (update ? baseUri.concat(`${entity.id}/`) : baseUri) + extraActionUri;
    const response: Observable<A> = update
      ? this.ads.patch(uri, _payload)
      : this.ads.post(uri, _payload);
    return response.pipe(
      first(),
      map((x) => this.entityConfig.deserializerFun(x)),
      tap((x) => {
        this.updateEntities(x);
        if (config?.notify ?? true) {
          this.matSnackBar.open(msg, '', { duration: 3500 });
        }
      })
    );
  }

  setEntitiesCount(payload: number): void {
    this.entitieCountSubject.next(payload);
  }
  get entitiesCount(): Observable<number> {
    return this.entitieCountSubject.asObservable();
  }
  get entities(): Observable<T[]> {
    return combineLatest([
      this.entitiesSubject.asObservable(),
      this.filters,
    ]).pipe(
      map(([entities, filters]: [T[], EntityFilter<T>[]]) =>
        filters.length > 0
          ? entities.filter((el: T) =>
              filters.some((entiFil) => entiFil.filterFun(el))
            )
          : entities
      )
    );
  }
  get filters(): Observable<any[]> {
    return this.filtersSubject.asObservable();
  }
  setEntities(entities: T[]) {
    this.entitiesSubject.next(entities);
  }

  get selectedEntityId(): Observable<number | undefined> {
    return this.selectedEntityIdSubject.asObservable();
  }
  setSelectedEntityId(payload: number | undefined): void {
    this.selectedEntityIdSubject.next(payload);
  }

  get selectedEntity(): Observable<T | undefined> {
    return combineLatest([this.entities, this.selectedEntityId]).pipe(
      first(),
      map(([entities, id]: [T[], number | undefined]) =>
        entities.find((entity) => entity.id === id)
      )
    );
  }

  updateEntities(entity: T): void {
    this.setEntities(
      upsertFn<T>(this.entitiesSubject.getValue(), entity, 'id')
    );
  }
  updateManyEntities(entities: T[]): void {
    entities.forEach((entity) => this.updateEntities(entity));
  }
  deleteEntity(entity: T): void {
    const _entities = [...this.entitiesSubject.getValue()];
    const _index = _entities.findIndex((ent) => ent.id === entity.id);
    if (_index >= 0) {
      _entities.splice(_index, 1);
    }
    this.setEntities(_entities);
  }
  upSertFilter<C extends Entity<T> = any, K extends KeysMatching<T, C> = any>(
    filter: EntityFilter<T, C, K>
  ) {
    const filters = this.filtersSubject.getValue();
    this.filtersSubject.next(
      upsertFn<EntityFilter<T, C, K>>(filters, filter, 'prop')
    );
  }

  fetchEntities = (): Observable<T[]> =>
    this.ads.get<A[]>(this.entityConfig.baseUri).pipe(
      first(),
      map((res) => res.map((unit) => this.entityConfig.deserializerFun(unit))),
      tap((res) => this.setEntities(res))
    );
}
