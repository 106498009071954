import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, combineLatest, filter, first } from 'rxjs';
import { AppQueryParamKey, Country } from 'src/app/models/shared.models';
import { GlobalStateService } from '../../state/global-state.service';

@Component({
  selector: 'app-locale-settings-dialog',
  templateUrl: './locale-settings-dialog.component.html',
  styleUrls: ['./locale-settings-dialog.component.scss'],
})
export class LocaleSettingsDialogComponent {
  countries$ = this.gss.countries;
  currencies$ = this.gss.currencies;
  shippingCountry$: Observable<Country>;
  selectedLanguage$ = this.gss.selectedLanguage;
  preferenceForm = new UntypedFormGroup({
    shippingCountry: new UntypedFormControl('', Validators.required),
    payment_currency: new UntypedFormControl('', Validators.required),
  });

  constructor(private router: Router, private gss: GlobalStateService) {
    combineLatest([this.gss.userShippingCountry, this.gss.userPaimentCurrency])
      .pipe(first())
      .subscribe((x) =>
        this.preferenceForm.setValue(
          {
            shippingCountry: x[0].alpha2Code,
            payment_currency: x[1].alphaCode,
          },
          {
            emitEvent: false,
          }
        )
      );

    this.preferenceForm.valueChanges
      .pipe(
        //skip(1),
        filter(() => this.preferenceForm.valid)
      )
      .subscribe((x) => {
        console.warn(x);
        this.router.navigate([], {
          queryParams: {
            [AppQueryParamKey.shippingCountry]: x.shippingCountry,
            [AppQueryParamKey.payment_currency]: x.payment_currency,
          },
        });
      });
  }
}
