import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Router, Scroll } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  centerSlideInAnimation,
  expandAnimation,
  sideSlideInAnimation,
} from './animations';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PreferencesBannerComponent } from 'src/app/core/components/preferences-banner/preferences-banner.component';
import { GlobalStateService } from 'src/app/core/state/global-state.service';
import { SessionStorageKey } from 'src/app/models/shared.models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [centerSlideInAnimation, expandAnimation, sideSlideInAnimation],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sidenav', { static: false }) sideNav!: MatSidenav;
  @ViewChild('sidenavContent', { read: MatSidenavContent })
  sidenavContentScrollable?: MatSidenavContent;
  isSideMenuActive = false;
  isLoading$: Observable<boolean>;
  showMenu$: Observable<boolean>;
  subscriptions$ = new Subject<any>();
  isSmall$ = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(map((x) => x.matches));
  constructor(
    private matDialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document,
    private gss: GlobalStateService,
    private router: Router
  ) {
    this.router.events.subscribe(() => this.sideNav.close());
    this.isLoading$ = this.gss.isLoading;

    if (this.isLocaleBannerClosed) {
      this.matDialog
        .open(PreferencesBannerComponent, {
          width: '420px',
          hasBackdrop: false,
          position: {
            left: '2rem',
            bottom: '2rem',
          },
        })
        .afterClosed()
        .subscribe(this.closeLocaleBanner);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions$.next('');
    this.subscriptions$.complete();
  }
  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof Scroll),
        takeUntil(this.subscriptions$)
      )
      .subscribe((event: Scroll) => {
        if (!this.sidenavContentScrollable) {
          return;
        }

        const nativeElement =
          this.sidenavContentScrollable.getElementRef().nativeElement;

        if (event.position) {
          // backward navigation
          nativeElement.scroll({
            top: event.position[1],
            left: event.position[0],
            behavior: 'smooth',
          });
        } else if (event.anchor) {
          // anchor navigation
          const offsetTop =
            this.document.getElementById(event.anchor)?.offsetTop || 0;

          nativeElement.scroll({
            top: offsetTop,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // forward navigation
          nativeElement.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });

          // An alternative the scrolling box scrolls in a smooth fashion, see browser compatibility before
          // https://developer.mozilla.org/en-US/docs/Web/API/Element/scroll#browser_compatibility

          //this.sidenavContentScrollable.scrollTo({left: 0, top: 0, behavior: 'smooth'});
        }
      });
  }
  toggleSideNav() {
    this.sideNav.toggle();
  }

  closeLocaleBanner(): void {
    sessionStorage.setItem(SessionStorageKey.bannerClosed, 'true');
  }
  get isLocaleBannerClosed(): boolean {
    return sessionStorage.getItem(SessionStorageKey.bannerClosed)
      ? JSON.parse(sessionStorage.getItem(SessionStorageKey.bannerClosed))
      : false;
  }
}
