<mat-sidenav-container>
  <mat-sidenav position="start" #sidenav mode="over">
    <app-side-nav-menu></app-side-nav-menu>
  </mat-sidenav>
  <mat-sidenav-content #sidenavContent class="side-content">
    <header class="sticky z-30 top-0 left-0 mat-elevation-z4">
      <app-product-menu (sideNavEmmiter)="toggleSideNav()"></app-product-menu>
      <mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate"></mat-progress-bar>
    </header>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>