import { Injectable, Injector } from '@angular/core';
import { EntityStore } from 'src/app/core/services/entity-store.base';
import {
  Contact,
  ContactApi,
  ContactForm,
  contactConfig,
} from 'src/app/models/entities/contact.entity';

@Injectable({
  providedIn: 'root',
})
export class ContactStateService extends EntityStore<
  Contact,
  ContactForm,
  ContactApi
> {
  constructor(injector: Injector) {
    super(injector);
    this.entityConfig = contactConfig;
  }
}
