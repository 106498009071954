import { InjectionToken } from '@angular/core';

export type StateInitType = 'all' | null;

export const ENTITY_CONFIG = new InjectionToken<any>('entityConfig');

// export const ENTITY_WRITE_FORM_COMP = new InjectionToken<ComponentType<BaseEntityFormComponent<any,any>>>('entityWriteFormComp');
export const ENTITY_STATE_INIT_TYPE = new InjectionToken<StateInitType>(
  'entityStateInitType',
  {
    factory: () => null,
  }
);
