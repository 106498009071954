import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CounterComponent,
    },
  ],
})
export class CounterComponent implements ControlValueAccessor {
  @Input() max = 10000;
  @Input() min = 0;

  touched = false;
  val = 0;
  _disabled = false;

  constructor() {}

  onChange = (a: any) => {};
  onTouched = () => {};

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  writeValue(obj: any): void {
    this.val = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  updateControlValue(direction: '+' | '-'): void {
    const newVal = direction === '+' ? this.val + 1 : this.val - 1;
    this.val = newVal <= this.max && newVal >= this.min ? newVal : this.val;
    this.touched = true;
    this.onChange(this.val);
  }
}
