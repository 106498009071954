import { Entity, ReadOnlyEntityConfig } from 'src/app/models/base/entity.base';

export interface ApiPaymentMethod {
  id: number;
  designation: string;
  description: string;
}

export class PaymentMethod extends Entity<PaymentMethod> {
  id: number;
  designation: string;
  description: string;

  static deserialize = (args: ApiPaymentMethod): PaymentMethod =>
    new PaymentMethod({
      id: args.id,
      designation: args.designation,
      description: args.description,
    });
}

export interface ApiPaymentVariant {
  id: number;
  default_fees: number;
  payment_method: ApiPaymentMethod;
  remarks: string;
  online_payment: boolean;
}
export class PaymentVariant extends Entity<PaymentVariant> {
  id: number;
  defaultFees: number;
  onlinePayment: boolean;
  paymentMethod: PaymentMethod;
  remarks: string;

  static deserialize = (args: ApiPaymentVariant): PaymentVariant =>
    new PaymentVariant({
      id: args.id,
      defaultFees: args.default_fees,
      onlinePayment: args.online_payment,
      remarks: args.remarks,
      paymentMethod: PaymentMethod.deserialize(args.payment_method),
    });
}

export const paymentVariantConfg: ReadOnlyEntityConfig<
  PaymentVariant,
  any,
  ApiPaymentVariant
> = {
  feature: $localize`payment variant`,
  baseUri: `shop/payment-variants/`,
  deserializerFun: PaymentVariant.deserialize,
};
