import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@auth0/auth0-angular';
import { merge } from 'rxjs';
import { filter, finalize, first, switchMap, tap } from 'rxjs/operators';
import { CartItemListDialogComponent } from 'src/app/account/components/cart-item-list-dialog/cart-item-list-dialog.component';
import { AppDataService } from 'src/app/core/services/app-data.service';
import { CartItemService } from 'src/app/core/state/cart-item.service';
import {
  CartItem,
  CartItemApi,
  cartItemConfig,
} from 'src/app/models/entities/quart-item.entity';
import { AppearanceVariant, Product } from 'src/app/models/product.models';
import { QuartDialogComponent } from '../../product/components/quart-dialog/quart-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ShopDialogService {
  constructor(
    private snackBar: MatSnackBar,
    private as: AuthService,
    private qis: CartItemService,
    private ads: AppDataService,
    private dialog: MatDialog
  ) {}

  openCartItemsDialog(): void {
    this.as.isAuthenticated$
      .pipe(
        first(),
        switchMap((x) => {
          if (x) {
            this.dialog.open(CartItemListDialogComponent, {
              width: '640px',
              maxWidth: '95vw',
            });
            return this.qis.fetchEntities();
          } else {
            return this.snackBar
              .open(
                $localize`you have to login before adding items to cart`,
                $localize`login`
              )
              .onAction()
              .pipe(tap(() => this.as.loginWithPopup()));
          }
        })
      )
      .subscribe();
  }
  openQuartDialog(
    product: Product,
    selectedAppearanceVariant?: AppearanceVariant
  ): void {
    this.as.isAuthenticated$
      .pipe(
        first(),
        switchMap((x) => {
          if (x) {
            let empty = true;
            const dialogRef = this.dialog.open(QuartDialogComponent, {
              width: '640px',
              maxWidth: '95vw',
              maxHeight: '100vh',
              data: { product, selectedAppearanceVariant },
            });
            return dialogRef.beforeClosed().pipe(
              filter((payload) => (payload ? true : false)),
              tap((x) => (empty = false)),
              switchMap((payload) =>
                merge(
                  ...CartItem.serialize(payload).map((_quartPayload) =>
                    this.ads
                      .post<CartItemApi>(
                        cartItemConfig.baseUri,
                        JSON.stringify(_quartPayload)
                      )
                      .pipe(
                        first(),
                        tap((quartItem) =>
                          this.qis.updateEntities(
                            CartItem.deserialize(quartItem)
                          )
                        )
                      )
                  )
                )
              ),
              finalize(() => (empty ? undefined : this.openCartItemsDialog()))
            );
          } else {
            return this.snackBar
              .open(
                $localize`you have to login before adding items to cart`,
                $localize`login`
              )
              .onAction()
              .pipe(tap(() => this.as.loginWithPopup()));
          }
        })
      )
      .subscribe();
  }
}
