import { Injectable } from '@angular/core';
import { AwsRum } from 'aws-rum-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CloudWatchLoggerService {
  awsRum = new AwsRum(
    environment.awsRum!.APPLICATION_ID,
    environment.awsRum!.APPLICATION_VERSION,
    environment.awsRum!.APPLICATION_REGION,
    environment.awsRum!.config
  );
}
