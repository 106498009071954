<header *ngIf="user$ | async as user; else guest" class="flex items-center gap-4 p-4">
  <img class="w-12 rounded-full" [src]="user.picture" />

  <div>
    <h3 class="mb-1">{{ user.name}}</h3>
    <p>{{ user.email }}</p>
  </div>
</header>
<ng-template #guest>
  <button class="m-4" (click)="openSignInDialog()" mat-stroked-button color="accent">
    <span i18n>login or create a new account</span>
  </button>
</ng-template>
<main>
  <mat-divider></mat-divider>
  <mat-nav-list class="titlecase">
    <div i18n class="capitalize" mat-subheader>products categories</div>
    <a mat-list-item mat-dialog-close [routerLink]="['/product/list']" queryParamsHandling="merge"
      [queryParams]="{ categories__designation__in: rootCat.designation }"
      *ngFor="let rootCat of rootCategories$ | async">
      <mat-icon [svgIcon]="rootCat.svgIcon" matListItemIcon></mat-icon>
      <span matListItemTitle>{{
        rootCat.designation | titlecase
        }}</span>
    </a>
    <mat-divider></mat-divider>
    <div class="capitalize" mat-subheader>preferences</div>
    <mat-list-item (click)="openPaimentCurrencyDialog()">
      <mat-icon matListItemIcon>payments</mat-icon>
      <span matListItemTitle i18n>payment currency</span>
      <span matListItemLine>{{
        (payment_currency$ | async)?.designation
        }}</span>
    </mat-list-item>
    <mat-list-item (click)="openShippingCoutryDialog()">
      <mat-icon matListItemIcon>local_shipping</mat-icon>
      <span matListItemTitle i18n>shipping country</span>
      <span matListItemLine>{{
        (shippingCountry$ | async)?.designation
        }}</span>
    </mat-list-item>
    <mat-list-item [matMenuTriggerFor]="lang">
      <mat-icon matListItemIcon>language</mat-icon>
      <span matListItemTitle i18n>language</span>
      <span matListItemLine>{{ (language$ | async)?.name }}</span>
    </mat-list-item>
    <ng-container *ngIf="isLogged$ | async">
      <mat-divider></mat-divider>
      <div i18n mat-subheader>profile</div>
      <a mat-list-item>
        <mat-icon matListItemIcon>account_circle</mat-icon>
        <span matListItemTitle i18n>my profile</span>
      </a>
      <a [queryParams]="{ profiles: (this.user$ | async)?.sub }" routerLink="/product/list" mat-list-item>
        <mat-icon matListItemIcon>favorite_border</mat-icon>
        <span matListItemTitle i18n>my favorites</span>
      </a>
      <a [queryParams]="{ profiles: (this.user$ | async)?.sub }" routerLink="/product/list" mat-list-item>
        <mat-icon matListItemIcon>shopping_cart</mat-icon>
        <span matListItemTitle i18n>my cart</span>
      </a>
      <a (click)="logOut()" mat-list-item>
        <mat-icon matListItemIcon>logout</mat-icon>
        <span matListItemTitle i18n>logout</span>
      </a>
    </ng-container>
    <mat-divider></mat-divider>
    <div mat-subheader>informations</div>
    <a fragment="about-us" [routerLink]="['/miscellaneous/home']" mat-list-item>
      <mat-icon matListItemIcon>business</mat-icon>
      <span matListItemTitle i18n>who we are</span>
    </a>
    <a fragment="contact-us" [routerLink]="['/miscellaneous/home']" mat-list-item>
      <mat-icon matListItemIcon>mail</mat-icon>
      <span matListItemTitle i18n>contact us</span>
    </a>
  </mat-nav-list>
</main>
<mat-menu #lang="matMenu">
  <mat-nav-list dense>
    <a mat-list-item [href]="getHref(item.id)" *ngFor="let item of languageList">
      <mat-icon matListItemIcon [svgIcon]="item.name"></mat-icon>
      <span matListItemTitle>{{ item.name }}</span>
    </a>
  </mat-nav-list>
</mat-menu>