import { Injectable, Injector } from '@angular/core';
import { EntityStore } from 'src/app/core/services/entity-store.base';
import {
  SiteContent,
  SiteContentApi,
  siteContentConfig,
} from 'src/app/models/entities/site-content.entity';

@Injectable({
  providedIn: 'root',
})
export class SiteContentStateService extends EntityStore<
  SiteContent,
  any,
  SiteContentApi
> {
  constructor(injector: Injector) {
    super(injector);
    this.entityConfig = siteContentConfig;
  }
}
