import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { CategoryMenuComponent } from 'src/app/core/components/category-menu/category-menu.component';
import { LanguageBoxComponent } from 'src/app/core/components/language-box/language-box.component';
import { LocaleSettingsDialogComponent } from 'src/app/core/components/locale-settings-dialog/locale-settings-dialog.component';
import { ProductMenuComponent } from 'src/app/core/components/product-menu/product-menu.component';
import { SideNavMenuComponent } from 'src/app/core/components/side-nav-menu/side-nav-menu.component';
import { CountryFlagPipe } from '../core/pipes/country-flag.pipe';
import { BackendErrorComponent } from './components/backend-error/backend-error.component';
import { SiteContentInjectorDirective } from './directives/site-content-injector.directive';
import { ResolveProductPriceFactorPipe } from './pipes/resolve-product-price-factor.pipe';
import { PreferencesBannerComponent } from './components/preferences-banner/preferences-banner.component';

@NgModule({
  declarations: [
    ProductMenuComponent,
    LocaleSettingsDialogComponent,
    CategoryMenuComponent,
    LanguageBoxComponent,
    CountryFlagPipe,
    SideNavMenuComponent,
    BackendErrorComponent,
    SiteContentInjectorDirective,
    ResolveProductPriceFactorPipe,
    PreferencesBannerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatListModule,
    MatSidenavModule,
    MatRippleModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
  ],
  exports: [
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    HttpClientModule,
    CountryFlagPipe,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatRippleModule,
    MatSnackBarModule,
    MatProgressBarModule,
    ProductMenuComponent,
    SiteContentInjectorDirective,
    SideNavMenuComponent,
    CategoryMenuComponent,
    ResolveProductPriceFactorPipe,
    PreferencesBannerComponent,
  ],
})
export class CoreModule {}
