/* eslint-disable @typescript-eslint/naming-convention */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserNameInjectorInterceptor implements HttpInterceptor {
  constructor(private as: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const _request = this.as.user$.pipe(
      switchMap((user) =>
        next.handle(
          request.clone({
            setHeaders: {
              ...(user && { authuser: user.sub }),
            },
          })
        )
      )
    );
    return environment.auth0.httpInterceptor.allowedList
      .map((x: any) => x.uri.replace('*', ''))
      .some((x) => request.url.includes(x))
      ? _request.pipe(
          tap(
            // Succeeds when there is a response; ignore other events
            // Operation failed; error is an HttpErrorResponse
            (error: any) => {
              // if(error.status === 401) {
              //   this.ass.logout()
              // }
              if (error instanceof HttpErrorResponse && error.status === 403) {
                //this._matSnackBar.open("You are not authorized to perform this action.",'',{duration:2500})
              }
            }
          )
        )
      : next.handle(request);
  }
}
