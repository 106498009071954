<button *ngIf="mode === 'large' else small" [matMenuTriggerFor]="lang" mat-button>
  <mat-icon class="mr2">language</mat-icon>
  <span>{{ (language$ | async)?.name.substr(0, 2) | uppercase }}</span>
</button>
<ng-template #small>
  <button [matMenuTriggerFor]="lang" mat-icon-button>
    <mat-icon matListItemIcon [svgIcon]="(language$ | async)?.name"></mat-icon>
  </button>
</ng-template>
<mat-menu #lang="matMenu">
  <mat-nav-list dense>
    <a mat-list-item [href]="getHref(item.id)" *ngFor="let item of languageList">
      <mat-icon matListItemIcon [svgIcon]="item.name"></mat-icon>
      <span matListItemTitle>{{ item.name }}</span>
    </a>
  </mat-nav-list>
</mat-menu>