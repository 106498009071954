import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/models/product.models';
import { Country, Currency, UserLanguage } from 'src/app/models/shared.models';
import { environment } from 'src/environments/environment';
import { DialogManagerService } from '../../services/dialog-manager.service';
import { GlobalStateService } from '../../state/global-state.service';

@Component({
  selector: 'app-side-nav-menu',
  templateUrl: './side-nav-menu.component.html',
  styleUrls: ['./side-nav-menu.component.scss'],
})
export class SideNavMenuComponent {
  isLogged$: Observable<boolean>;
  rootCategories$: Observable<Category[]>;
  payment_currency$: Observable<Currency>;
  shippingCountry$: Observable<Country>;
  language$: Observable<UserLanguage | null>;
  initials$: Observable<string>;
  languageList: UserLanguage[];
  user$ = this.as.user$;
  constructor(
    private as: AuthService,
    private dms: DialogManagerService,
    private gss: GlobalStateService
  ) {
    this.languageList = environment.supportedLanguages;
    this.isLogged$ = this.as.isAuthenticated$;
    this.payment_currency$ = this.gss.userPaimentCurrency;
    this.shippingCountry$ = this.gss.userShippingCountry;
    this.language$ = this.gss.selectedLanguage;
    this.rootCategories$ = this.gss.categories.pipe(
      map((categories) => categories.filter((cat) => cat.isRoot))
    );
  }

  logOut(): void {
    this.as.logout();
  }
  openShippingCoutryDialog() {
    this.dms.openLocaleSettingsDialog();
  }
  openPaimentCurrencyDialog() {
    this.dms.openLocaleSettingsDialog();
  }
  getHref(localId: string) {
    return '/' + localId;
  }
  openSignInDialog(): void {
    this.as.loginWithPopup();
  }
}
