import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { AnonymousUriInterceptor } from 'src/app/http-interceptors/anonymous-uri.interceptor';
import { environment } from 'src/environments/environment';
import { LocaleInterceptor } from './locale.interceptor';
import { UserNameInjectorInterceptor } from './username-injector.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  ...(environment.environment === 'dev-local'
    ? [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: UserNameInjectorInterceptor,
          multi: true,
        },
      ]
    : []),
  ...(environment.environment !== 'dev-local'
    ? [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AnonymousUriInterceptor,
          multi: true,
        },
      ]
    : []),
  { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
];
