import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EntityStore } from 'src/app/core/services/entity-store.base';
import { GlobalStateService } from 'src/app/core/state/global-state.service';
import {
  CartItem,
  CartItemApi,
  CartItemForm,
  cartItemConfig,
} from 'src/app/models/entities/quart-item.entity';

@Injectable({
  providedIn: 'root',
})
export class CartItemService extends EntityStore<
  CartItem,
  CartItemForm,
  CartItemApi
> {
  constructor(
    injector: Injector,
    //private ps: ProfileService,
    private gss: GlobalStateService
  ) {
    super(injector);
    this.entityConfig = cartItemConfig;
    this.gss.localeChanged
      .pipe(
        withLatestFrom(this.availableCartItems),
        filter(([_, items]: [any, CartItem[]]) => items.length > 0),
        switchMap(([_, items]: [any, CartItem[]]) =>
          this.ads.get<CartItemApi[]>(
            `${this.entityConfig.baseUri}`,
            new Map<string, string[]>().set(
              'id__in',
              items.map((item) => item.id.toString())
            )
          )
        ),
        tap((res) =>
          this.updateManyEntities(
            res.map((cartItem) => this.entityConfig.deserializerFun(cartItem))
          )
        )
      )
      .subscribe((x) => {});

    // this.ps.profile
    //   .pipe(
    //     distinctUntilKeyChanged(
    //       'cart_items',
    //       (a, b) => JSON.stringify(a) === JSON.stringify(b)
    //     ),
    //     switchMap(() => this.ads.get<CartItemApi[]>(this.entityConfig.baseUri)),
    //     map((res) =>
    //       res.map((unit) => this.entityConfig.deserializerFun(unit))
    //     ),
    //     tap((res) => this.setEntities(res))
    //   )
    //   .subscribe(() => {});
  }

  // get entities(): Observable<CartItem[]> {
  //   return super.entities.pipe(
  //     withLatestFrom(this.ps.profile),
  //     map(([items, profile]: [CartItem[], Profile]) =>
  //       items.filter((item) => item.account === profile.id)
  //     )
  //   );
  // }

  get entitiesCount(): Observable<number> {
    return this.entities.pipe(map((entities) => entities.length));
  }

  get availableCartItems(): Observable<CartItem[]> {
    return this.entities.pipe(
      map((items: CartItem[]) => items.filter((item) => !item.hasOrder))
    );
  }

  get cartCount(): Observable<number> {
    return this.availableCartItems.pipe(map((entities) => entities.length));
  }
  get totalPrice(): Observable<number> {
    return this.availableCartItems.pipe(
      map((entites) =>
        entites.reduce(
          (acc: number, current: CartItem) => acc + current.currentPrice,
          0
        )
      )
    );
  }
  get totalQuantity(): Observable<number> {
    return this.availableCartItems.pipe(
      map((entites) =>
        entites.reduce(
          (acc: number, current: CartItem) => acc + current.quantity,
          0
        )
      )
    );
  }
}
