import { Entity, ReadOnlyEntityConfig } from 'src/app/models/base/entity.base';
import {
  ApiPaymentVariant,
  PaymentVariant,
} from 'src/app/models/payment-modality.model';

export interface ApiShippingMethod {
  id: number;
  designation: string;
  description: string;
  adress_required: boolean;
}

export class ShippingMethod extends Entity<ShippingMethod> {
  id: number;
  designation: string;
  description: string;
  adressRequired: boolean;

  static deserialize = (args: ApiShippingMethod): ShippingMethod =>
    new ShippingMethod({
      id: args.id,
      designation: args.designation,
      description: args.description,
      adressRequired: args.adress_required,
    });
}

export interface ApiShippingVariant {
  id: number;
  default_fees: number;
  shipping_method: ApiShippingMethod;
  remarks: string;
  payment_variants: ApiPaymentVariant[];
}
export class ShippingVariant extends Entity<ShippingVariant> {
  id: number;
  defaultFees: number;
  shippingMethod: ShippingMethod;
  remarks: string;
  paymentVariants: PaymentVariant[];

  static deserialize = (args: ApiShippingVariant): ShippingVariant =>
    new ShippingVariant({
      id: args.id,
      defaultFees: args.default_fees,
      remarks: args.remarks,
      shippingMethod: ShippingMethod.deserialize(args.shipping_method),
      paymentVariants: args.payment_variants.map((_payVar) =>
        PaymentVariant.deserialize(_payVar)
      ),
    });
}

export const shippingVariantConfg: ReadOnlyEntityConfig<
  ShippingVariant,
  any,
  ApiShippingVariant
> = {
  feature: $localize`shipping variant`,
  baseUri: `shop/shipping-variants/`,
  deserializerFun: ShippingVariant.deserialize,
};
