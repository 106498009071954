import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogManagerService } from 'src/app/core/services/dialog-manager.service';
import { GlobalStateService } from 'src/app/core/state/global-state.service';

@Component({
  selector: 'app-preferences-banner',
  templateUrl: './preferences-banner.component.html',
  styleUrls: ['./preferences-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreferencesBannerComponent {
  payment_currency$ = this.gss.userPaimentCurrency;
  shippingCountry$ = this.gss.userShippingCountry;
  constructor(
    private dms: DialogManagerService,
    private gss: GlobalStateService
  ) {}

  openShippingCoutryDialog() {
    this.dms.openLocaleSettingsDialog();
  }
  openPaimentCurrencyDialog() {
    this.dms.openLocaleSettingsDialog();
  }
}
