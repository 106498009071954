import { Params } from '@angular/router';
import { CartForm, CartItem } from 'src/app/models/entities/quart-item.entity';
export type Country = {
  id: number;
  designation: string;
  alpha2Code: string;
  alpha3Code: string;
  numCode: string;
  currency: string;
  tax_rate: number;
};
export type Region = {
  id: number;
  designation: string;
  tax_rate: string;
};
export type Currency = {
  id: number;
  designation: string;
  symbol: string;
  country: string;
  alphaCode: string;
  numCode: number;
};
export type CarouselMode = 'vertical' | 'horizontal';
export type RedirectDataType = { redirectUrl: string; params: Params };
export type LocaleInitData = {
  countries: Country[];
  currencies: Currency[];
};

export enum SessionStorageKey {
  shippingCountry = 'SHIPPING_COUNTRY',
  payment_currency = 'PAYMENT_CURRENCY',
  bannerClosed = 'BANNER_CLOSED',
}

export enum AppQueryParamKey {
  shippingCountry = 'shippingCountry',
  payment_currency = 'payment_currency',
}

export enum LanguageType {
  ENGLISH = 'English',
  FRENCH = 'Francais',
  GERMAN = 'Deutch',
}
export interface UserLanguage {
  id: string;
  name: string;
}
export type VariantPriceFactor = {
  id: number;
  variant_price_factor: number;
};
export interface ProductPriceFactor {
  id: number;
  base_price: number;
  appearanceVariants: Array<VariantPriceFactor>;
  dimensionVariants: Array<VariantPriceFactor>;
  currency_factor: number;
  global: number;
  tax: number;
  minPrice: number;
  maxPrice: number;
  hasSinglePrice: boolean;
}

export class ProductPriceFactor {
  constructor(args: ProductPriceFactor, baseFactor: PriceFactor) {
    Object.assign(this, args);
    this.global = baseFactor.global;
    this.tax = baseFactor.tax;
    this.minPrice = Math.round(
      this.applyFactors(
        Math.min(...this.dimensionVariants.map((x) => x.variant_price_factor)) *
          Math.min(
            ...this.appearanceVariants.map((x) => x.variant_price_factor)
          )
      )
    );
    this.maxPrice = Math.round(
      this.applyFactors(
        Math.max(...this.dimensionVariants.map((x) => x.variant_price_factor)) *
          Math.max(
            ...this.appearanceVariants.map((x) => x.variant_price_factor)
          )
      )
    );
    this.hasSinglePrice = this.minPrice === this.maxPrice;
  }

  variantPrice = (dimId: number, appId: number): number | undefined => {
    const price = this.applyFactors(
      this.appearanceVariants.find((x) => x.id === appId)
        ?.variant_price_factor *
        this.dimensionVariants.find((x) => x.id === dimId)?.variant_price_factor
    );
    return price;
  };

  applyFactors = (amount: number | undefined): number | undefined =>
    amount * this.currency_factor * this.base_price * this.tax * this.global;
}
export interface PriceFactor {
  global: number;
  tax: number;
  products: Array<ProductPriceFactor>;
}

export class PriceFactor {
  constructor(args: PriceFactor) {
    Object.assign(this, args);
    this.products = this.products.map(
      (product) => new ProductPriceFactor(product, this)
    );
  }

  variantPrice = (
    dimId: number | string,
    appId: number | string
  ): number | undefined => {
    const price = this.products
      .map((x) =>
        x.variantPrice(parseInt(dimId.toString()), parseInt(appId.toString()))
      )
      .find((x) => !Number.isNaN(x));
    return price;
  };

  getCartItemsPrice = (items: CartItem[]) =>
    items.reduce(
      (total: number, item: CartItem) =>
        total +
        item.quantity *
          this.variantPrice(
            item.dimensionVariant.id,
            item.appearanceVariant.id
          ),
      0
    );
  getCartPrice = (quartForm: CartForm): number =>
    Object.keys(quartForm).reduce((appAcc: number, currentApp: string) => {
      return (
        appAcc +
        Object.keys(quartForm[parseInt(currentApp)]).reduce(
          (dimAcc: number, currentDim: string) => {
            return (
              dimAcc +
              this.variantPrice(parseInt(currentDim), parseInt(currentApp)) *
                quartForm[parseInt(currentApp)][parseInt(currentDim)]
            );
          },
          0
        )
      );
    }, 0);
}
