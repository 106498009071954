export const PARAM_KEYS_MAP = {
  PAGE: 'page',
  SEARCH: 'search',
  CAT_DESIGNATION: 'categories__designation__in',
};

type UtilRouteKeys =
  | 'signIn'
  | 'createProfile'
  | 'profileEdit'
  | 'cartSummary'
  | 'cancelRightOutlet';
const UTIL_ROUTES: Record<UtilRouteKeys, any[]> = {
  signIn: ['/', { outlets: { right: ['account', 'sign-in'] } }],
  createProfile: ['/', { outlets: { right: ['account', 'new-account'] } }],
  profileEdit: [
    '/',
    {
      outlets: {
        right: ['account', 'profile-menu', { outlets: { profile: ['edit'] } }],
      },
    },
  ],
  cartSummary: [
    '',
    {
      outlets: {
        right: ['account', 'profile-menu', { outlets: { profile: ['cart'] } }],
      },
    },
  ],
  cancelRightOutlet: ['', { outlets: { right: null } }],
};
