import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {
  MAT_ICON_DEFAULT_OPTIONS,
  MatIconDefaultOptions,
} from '@angular/material/icon';

export const materialProviders = [
  // {
  //   provide: MAT_DIALOG_DEFAULT_OPTIONS,
  //   useValue: {} as MatDialogConfig,
  // },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
    } as MatFormFieldDefaultOptions,
  },
  {
    provide: MAT_ICON_DEFAULT_OPTIONS,
    useValue: {
      fontSet: 'material-symbols-outlined',
    } as MatIconDefaultOptions,
  },
  { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
];
