import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@auth0/auth0-angular';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  first,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { AppDataService } from 'src/app/core/services/app-data.service';
import { CartItemService } from 'src/app/core/state/cart-item.service';
import { ContactStateService } from 'src/app/core/state/contact-state.service';
import {
  Profile,
  ProfileApi,
  ProfileForm,
  profileConfig,
} from 'src/app/models/entities/account.entity';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private profileSubject = new BehaviorSubject<Profile | null>(null);
  private extractStorageFavorites = (): number[] =>
    JSON.parse(localStorage.getItem('favorites') ?? JSON.stringify([]));
  private storageFavoritesSubject = new BehaviorSubject<number[]>(
    this.extractStorageFavorites()
  );
  constructor(
    private css: ContactStateService,
    private cis: CartItemService,
    private matSnackBar: MatSnackBar,
    private ads: AppDataService,
    private as: AuthService
  ) {
    this.as.user$
      .pipe(
        switchMap((user) =>
          user
            ? this.ads
                .get(`account/profiles/${user.sub}/`)
                .pipe(map((x) => profileConfig.deserializerFun(x)))
            : of(null)
        )
      )
      .subscribe((x) => {
        this.profileSubject.next(x);
        this.cis.fetchEntities().subscribe();
        this.css.fetchEntities().subscribe();
      });
  }

  get profile(): Observable<Profile | null> {
    return this.profileSubject.asObservable();
  }

  updateProfile(payload: Partial<ProfileForm>): void {
    this.ads
      .patch<ProfileApi>(
        `account/profiles/${this.profileSubject.getValue().username}/`,
        JSON.stringify(Profile.serialize(payload))
      )
      .pipe(
        first(),
        map((x) => profileConfig.deserializerFun(x)),
        tap((x) => {
          this.matSnackBar.open(
            $localize`Profile informations successfully updated`
          );
          this.profileSubject.next(x);
        })
      )
      .subscribe();
  }

  toogleFavorite(id: number): void {
    let profile = this.profileSubject.getValue();
    if (profile) {
      profile = new Profile({
        ...profile,
        favorites: profile.favorites.includes(id)
          ? profile.favorites.filter((x) => x !== id)
          : profile.favorites.concat(id),
      });
      this.profileSubject.next(profile);
      this.updateProfile(profile);
    } else {
      let fav = this.storageFavoritesSubject.getValue();
      fav = fav.includes(id) ? fav.filter((x) => x !== id) : fav.concat(id);
      localStorage.setItem('favorites', JSON.stringify(fav));
      this.storageFavoritesSubject.next(fav);
    }
  }

  get storageFavorites(): Observable<number[]> {
    return this.storageFavoritesSubject.asObservable();
  }

  get favorites(): Observable<number[]> {
    return combineLatest([this.profile, this.storageFavorites]).pipe(
      map(([profile, storageFavorites]: [Profile | null, number[]]) =>
        profile ? profile.favorites : storageFavorites
      )
    );
  }
}
