<span mat-dialog-title i18n class="first-letter:uppercase">Your cart</span>

<ng-container *ngIf="(quartItems$ | async).length > 0; else noItem">
  <main mat-dialog-content>
    <h3 class="flex text-black p-2 justify-between items-center bg-gray-100 rounded-sm">
      <span>Total</span>
      <div>
        <span>
          {{ totalQuantity$ | async | i18nPlural : messageMapping }}
        </span>
        <span class="mat-h3 m-0">{{
          totalPrice$
          | async
          | currency
          : (currency$ | async).alphaCode
          : 'symbol-narrow'
          : '1.0-0'
          }}
        </span>
      </div>
    </h3>
    <app-cart-item-list [currencyCode]="(currency$ | async).alphaCode"
      [cartItems]="quartItems$ | async"></app-cart-item-list>
  </main>
  <footer mat-dialog-actions>
    <button matDialogClose [routerLink]="['/', 'account', 'create-order']" color="accent" mat-flat-button>
      <mat-icon>local_shipping</mat-icon>
      <span i18n>order</span>
    </button>
  </footer>

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent let-cart="cart">
      <button mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span i18n="edit|action">edit</span>
      </button>
      <button (click)="removeCartItem(cart)" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span i18n="delete|action">delete</span>
      </button>
    </ng-template>
  </mat-menu>
</ng-container>
<ng-template #noItem>
  <div mat-dialog-content class="mt-8 flex flex-col items-center">
    <mat-icon class="text-gray-800 empty-icon mb-6" inline="true">production_quantity_limits</mat-icon>
    <h2 i18n class="capitalize">your cart is empty</h2>
  </div>
</ng-template>