import { LanguageType } from "src/app/models/shared.models";

export const baseEnviroment: any = {
  supportedLanguages : [
    {
      id: 'fr',
      name: LanguageType.FRENCH,
    },
    {
      id: 'en-US',
      name: LanguageType.ENGLISH,
    },
    {
      id: 'de',
      name: LanguageType.GERMAN,
    },
  ]
};