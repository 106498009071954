import { Entity, EntityConfig } from 'src/app/models/base/entity.base';

export interface ContactApi {
  id: number | string;
  name: string;
  phone_number: string;
  email?: string;
  company_tax_id: string;
  adress_line1: string;
  adress_line2: string;
  zip_code: string;
  city: string;
  region: string;
  raw: string;
}
export interface Contact extends ContactApi {}
export type ContactApiWrite = Omit<ContactApi, 'id'>;
export interface ContactForm {
  hasAdress: boolean;
  name: string;
  phone_number: string;
  email?: string;
  company_tax_id: string;
  adress: {
    adress_line1: string;
    adress_line2: string;
    zip_code: string;
    town: string;
    city: string;
    region: string;
    raw: string;
  };
}
export const hasAdress = (arg: any): boolean => {
  const adress = arg.adress ?? arg;
  return adress.adress_line1 && adress.city && adress.zip_code ? true : false;
};

export class Contact extends Entity<Contact> {
  static deserialize = (arg: ContactApi): Contact => new Contact(arg);
  static serialize = (arg: ContactForm): ContactApiWrite => ({
    name: arg.name,
    email: arg.email,
    company_tax_id: arg.company_tax_id,
    phone_number: arg.phone_number,
    adress_line1: arg.adress?.adress_line1,
    adress_line2: arg.adress?.adress_line2,
    zip_code: arg.adress?.zip_code,
    city: arg.adress?.city,
    region: arg.adress?.region,
    raw: arg.adress?.raw,
  });
  toString = (): string => `${this.name}`;
}

export const contactConfig: EntityConfig<Contact, ContactForm, ContactApi> = {
  baseUri: 'account/contacts/',
  feature: 'Contact',
  serializerFun: Contact.serialize,
  deserializerFun: Contact.deserialize,
};
