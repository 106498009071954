<mat-toolbar class="bg-white gap-0 sm:gap-4 px-1 sm:px-4">
  <button (click)="toggleSideNav()" *ngIf="(isWeb$ | async) === false" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <a class="nav-bar__logo" [routerLink]="['/miscellaneous/home/']">
    <h1 class="m-0">SMC</h1>
  </a>
  <div *ngIf="isWeb$ | async">
    <app-category-menu></app-category-menu>
    |
    <a color="accent" [matMenuTriggerFor]="informations" mat-button>
      <mat-icon>help_outline</mat-icon>
      <span i18n>informations</span>
    </a>
  </div>
  <div class="max-w-sm w-full ml-4 rounded-md flex items-center gap-2 bg-gray-100 p-2">
    <mat-icon *ngIf="(isSmall$ | async) === false">search</mat-icon>
    <input class="sm:w-full w-36 bg-gray-100 text-base text-black flex-1" [formControl]="searchControl"
      placeholder="Search" i18-palceholder type="search">
  </div>

  <div class="ml-auto flex items-center gap-2">
    <ng-container *ngIf="(isSmall$ | async) === false">
      <a *ngIf="isLogged$ | async; else anonymous" class="rounded-full hover:cursor-pointer" matRipple
        [routerLink]="['/','account','profile-menu']">
        <img [src]="(user$ | async)?.picture" width="24" height="24">
      </a>
    </ng-container>
    <app-language-box [mode]="(isSmall$ | async) ? 'small':'large'" class="lang-box"> </app-language-box>
    <button *ngIf="(isSmall$ | async) === false" [matMenuTriggerFor]="shopMenu" mat-button>
      <mat-icon>shopping_cart</mat-icon>
      <span>{{ ((isSmall$ | async) ? (shippingCountry$ | async)?.alpha2Code : (shippingCountry$ | async)?.designation )
        | uppercase }} -
        {{ (payment_currency$ | async)?.symbol }}</span>
    </button>
  </div>
</mat-toolbar>
<ng-template #anonymous>
  <button [routerLink]="['/','account','profile-menu']" mat-stroked-button>
    <mat-icon>login</mat-icon>
    <span i18n>login</span>
  </button>
</ng-template>

<mat-menu #shopMenu="matMenu">
  <button (click)="openPaimentCurrencyDialog()" mat-menu-item>
    <mat-icon>payments</mat-icon>
    <span class="mat-subtitle-2">{{
      (payment_currency$ | async)?.designation
      }}</span>
  </button>
  <button (click)="openShippingCoutryDialog()" mat-menu-item>
    <mat-icon>local_shipping</mat-icon>
    <span class="mat-subtitle-2">{{
      (shippingCountry$ | async)?.designation
      }}</span>
  </button>
  <button (click)="shopDialogHandler.openCartItemsDialog()" mat-menu-item>
    <mat-icon>shopping_basket</mat-icon>
    <span i18n="my cart" class="titlecase mat-subtitle-2">my cart</span>
  </button>
</mat-menu>

<mat-menu class="titlecase" #informations="matMenu">
  <button fragment="about-us" [routerLink]="['/miscellaneous/home']" mat-menu-item>
    <mat-icon>business</mat-icon>
    <span i18n="who we are" class="mat-subtitle-2">who we are</span>
  </button>
  <button fragment="contact-us" [routerLink]="['/miscellaneous/home']" mat-menu-item>
    <mat-icon>mail</mat-icon>
    <span i18n="contact us" class="mat-subtitle-2">contact us</span>
  </button>
</mat-menu>