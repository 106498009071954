import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { GlobalStateService } from 'src/app/core/state/global-state.service';
import { UserLanguage } from 'src/app/models/shared.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language-box',
  templateUrl: './language-box.component.html',
  styleUrls: ['./language-box.component.scss'],
})
export class LanguageBoxComponent {
  @Input() mode: 'large' | 'small' = 'large';
  language$: Observable<UserLanguage | null>;
  languageList: UserLanguage[];
  constructor(private gss: GlobalStateService) {
    this.languageList = environment.supportedLanguages;
    this.language$ = this.gss.selectedLanguage;
  }
  getHref(localId: string) {
    return '/' + localId;
  }
}
