<mat-list>
    <ng-container *ngFor="let quartItem of cartItems">
        <mat-list-item>
            <img *ngIf="isSmallScreen === false" matListItemAvatar
                [src]="quartItem.appearanceVariant.thumbNail.content | mergeSizeQs:100" />
            <span matListItemTitle>{{ quartItem.product.designation }}</span>
            <span matListItemLine>{{ quartItem.appearanceVariant.look.material | uppercase }} -
                {{ quartItem.dimensionVariant.designation | uppercase }}</span>
            <span matListItemMeta class="flex items-center gap-1">
                <span>x {{ quartItem.quantity }}</span>
                =
                <span>
                    {{
                    currentPrice(quartItem)
                    | currency
                    : currencyCode
                    : 'symbol-narrow'
                    : '1.0-0'
                    }}
                </span>
            </span>
        </mat-list-item>
    </ng-container>
</mat-list>