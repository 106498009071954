<button
  [matMenuTriggerData]="{ category: root }"
  [matMenuTriggerFor]="menu"
  mat-button
  *ngFor="let root of rootCategories$ | async"
>
  <span i18n>{{ root.designation | titlecase }}</span>
</button>
<mat-menu #menu>
  <ng-template matMenuContent let-category="category">
    <button
      *ngFor="let item of category.children"
      mat-menu-item
      [routerLink]="['/product/list']"
      [queryParams]="{ categories__designation__in: item.designation }"
    >
      {{ item.designation | titlecase }}
    </button>
  </ng-template>
</mat-menu>
