import { IEnvironment } from 'src/app/environment.interface';
import { baseEnviroment } from 'src/environments/environment.base';

export const environment: IEnvironment = {
  ...baseEnviroment,
  production: false,
  environment: 'staging',
  host: 'https://staging.smcuivre.com',
  apiUri: 'https://rest.api.staging.smcuivre.com/',
  auth0: {
    domain: 'smc-staging.eu.auth0.com',
    clientId: 'h6w4WLs9DVXk9GWToPzOvjUpNwCqSmGh',
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    useRefreshTokensFallback: true,
    authorizationParams: {
      display: 'popup',
      redirect_uri: `${window.location.origin}`,
      audience: 'https://rest.api.staging.smcuivre.com',
      scope: 'offline_access profile email',
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://rest.api.staging.smcuivre.com*',
          allowAnonymous: true,
        },
      ],
    },
  },
  awsRum: {
    config: {
      sessionSampleRate: 1,
      guestRoleArn:
        'arn:aws:iam::839215185105:role/staging-rum-identity-pool-unauthenticated-role',
      identityPoolId: 'eu-west-1:10fdfe4a-aa62-4961-bb1b-407a23bcab34',
      endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
      telemetries: ['errors', 'http', 'performance'],
      allowCookies: true,
      enableXRay: false,
    },
    APPLICATION_ID: '88a53456-b5e5-4467-8c80-8e3ff96cee57',
    APPLICATION_VERSION: '1.0.0',
    APPLICATION_REGION: 'eu-west-1',
  },
};
