import { ErrorHandler, Injectable } from '@angular/core';
import { CloudWatchLoggerService } from 'src/app/core/services/cloud-watch-logger.service';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandlerService implements ErrorHandler {
  constructor(private cwls: CloudWatchLoggerService) {}
  handleError(error: Error): void {
    console.error(error);
    this.cwls.awsRum.recordError(error);
  }
}
