import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { BackendError } from 'src/app/utils/utils';

@Component({
  selector: 'app-backend-error',
  templateUrl: './backend-error.component.html',
  styleUrls: ['./backend-error.component.scss'],
})
export class BackendErrorComponent {
  non_field_errors: string;
  detail_error: string;
  field_errors: { [key: string]: string };

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: BackendError<any>) {
    this.non_field_errors = data.non_field_errors;
    this.detail_error = data.detail;
    delete data.non_field_errors;
    delete data.detail;
    this.field_errors = data;
  }
}
