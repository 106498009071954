import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AnonymousUriInterceptor implements HttpInterceptor {
  constructor(private as: AuthService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.as.isAuthenticated$.pipe(
      switchMap((isAuth) =>
        isAuth
          ? next.handle(request)
          : request.url.includes(environment.apiUri)
          ? next.handle(
              request.clone({
                url: request.url.replace(
                  environment.apiUri,
                  environment.apiUri + 'public/'
                ),
              })
            )
          : next.handle(request)
      )
    );
  }
}
