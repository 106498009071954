import { Entity, EntityConfig } from 'src/app/models/base/entity.base';
import {
  AdressApi
} from 'src/app/models/entities/adress.entity';

export interface ProfileApi {
  id: number | string;
  company?: { label: string; tax_id: string; adress: AdressApi };
  phone_number: string;
  username : string;
  favorites: number[];
  gender: number;
  cart_items?: number[];
}
export type ProfileWriteApi = Omit<ProfileApi,"id"|"username" >
export type ProfileForm = Omit<ProfileApi,"id"|"username" >

export interface Profile extends ProfileApi {}
export class Profile extends Entity<Profile> {
  constructor(args: Partial<Profile>) {
    super(args);
  }

  static deserialize = (obj: ProfileApi): Profile => new Profile(obj);
  static serialize = (obj: Partial<Profile>): Partial<ProfileWriteApi> => obj;
  get civilityStr(): string {
    return this.gender === 1 ? 'Mr' : 'Mme';
  }
  // toString = (): string =>
  //   this.fullName ?? this.displayName ?? this.email ?? this.phone_number;
}
export const profileConfig: EntityConfig<Profile, ProfileForm> = {
  baseUri: 'account/profiles/',
  feature: 'Profile',
  serializerFun: Profile.serialize,
  deserializerFun: Profile.deserialize,
};
