import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';
import { GlobalStateService } from 'src/app/core/state/global-state.service';
import { ProductPriceFactor } from 'src/app/models/shared.models';

@Pipe({
  name: 'resolveProductPriceFactor',
})
export class ResolveProductPriceFactorPipe implements PipeTransform {
  constructor(private gss: GlobalStateService) {}
  transform(productId: number): Observable<ProductPriceFactor | undefined> {
    return this.gss.priceFactor$.pipe(
      map((priceFactor) =>
        priceFactor.products.find((product) => product.id === productId)
      )
    );
  }
}
