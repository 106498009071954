import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { AppDataService } from 'src/app/core/services/app-data.service';
import { EntityStore } from 'src/app/core/services/entity-store.base';
import { Entity, EntityConfig } from 'src/app/models/base/entity.base';
import { ENTITY_CONFIG } from 'src/app/models/tokens';

@Injectable({
  providedIn: 'root',
})
export class DataManagerService {
  constructor(
    @Inject(ENTITY_CONFIG) private entityConfig: EntityConfig,
    private eds: EntityStore,
    private ads: AppDataService,
    private matSnackBar: MatSnackBar
  ) {}

  upsert(payload: any, entity?: any): Observable<any> {
    const update = entity !== undefined;
    const feature = this.entityConfig.feature;
    const baseUri = this.entityConfig.baseUri;
    const _payload = JSON.stringify(this.entityConfig.serializerFun(payload));
    const msg = `successfully ${update ? 'updated' : 'created'} ${feature}`;
    const uri = update ? baseUri.concat(`${entity.id}/`) : baseUri;
    const response: Observable<any> = update
      ? this.ads.patch(uri, _payload)
      : this.ads.post(uri, _payload);
    return response.pipe(
      first(),
      tap((x) => {
        this.eds.updateEntities(this.entityConfig.deserializerFun(x));
        this.matSnackBar.open(msg, '', { duration: 3500 });
      })
    );
  }

  delete(entity: Entity): Observable<any> {
    return this.ads.delete(`${this.entityConfig.baseUri}${entity.id}/`).pipe(
      first(),
      tap(() => {
        this.eds.deleteEntity(entity);
        this.matSnackBar.open(
          `${
            this.entityConfig.feature
          } ${entity.toString()} successfully deleted`,
          '',
          { duration: 3500 }
        );
      })
    );
  }
}
