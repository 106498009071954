import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayExists',
})
export class ArrayExistsPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) {
      if (Array.isArray(value)) {
        return (
          value.some((el) =>
            el || (el && Array.isArray(el) && el.length) ? true : false
          ) && value.length > 0
        );
      } else if (value instanceof Set) {
        return (
          Array.from(value).some((el) => (el ? true : false)) && value.size > 0
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
