import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  private loadingUrisSubject = new BehaviorSubject<string[]>([]);
  private pushUri(uri: string): void {
    this.loadingUrisSubject.next([...this.loadingUrisSubject.getValue(), uri]);
  }
  private removeUri(uri: string): void {
    this.loadingUrisSubject.next(
      this.loadingUrisSubject.getValue().filter((_uri) => _uri !== uri)
    );
  }

  get apiLoading(): Observable<boolean> {
    return this.loadingUrisSubject
      .asObservable()
      .pipe(map((uris) => uris.length > 0));
  }

  constructor(private errorHandler: ErrorHandler, private http: HttpClient) {}
  get<T>(
    endPoint: string,
    paramMap?: Map<string, string[]> | ParamMap,
    withLoad = false
  ): Observable<T> {
    if (withLoad) {
      this.pushUri(endPoint);
    }
    let httpParams = new HttpParams();
    const query: string = [environment.apiUri, endPoint].join('');
    if (paramMap) {
      if (paramMap instanceof Map) {
        (paramMap as Map<string, string[]>).forEach((value, key) => {
          value.forEach((val) => (httpParams = httpParams.append(key, val)));
        });
      } else {
        paramMap.keys.forEach((key) => {
          httpParams = httpParams.set(key, paramMap.get(key) ?? '');
        });
      }
    }
    const options = {
      params: httpParams,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<T>(query, options).pipe(
      first(),
      catchError(this.handleError),
      finalize(() => this.removeUri(endPoint))
    );
  }
  post<T>(endPoint: string, payload: any): Observable<T> {
    this.pushUri(endPoint);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const query: string = [environment.apiUri, endPoint].join('');
    return this.http.post<T>(query, payload, httpOptions).pipe(
      first(),
      catchError((error) => {
        this.removeUri(endPoint);
        return this.handleError(error);
      }),
      finalize(() => this.removeUri(endPoint))
    );
  }
  patch<T>(endPoint: string, payload: any): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const query: string = [environment.apiUri, endPoint].join('');
    return this.http.patch<T>(query, payload, httpOptions).pipe(
      first(),
      catchError((error) => this.handleError(error)),
      finalize(() => this.removeUri(endPoint))
    );
  }

  delete(endPoint: string): Observable<any> {
    const query: string = [environment.apiUri, endPoint].join('');
    return this.http.delete(query).pipe(
      first(),
      catchError((error) => this.handleError(error))
    );
  }
  downloadPdf(endPoint: string): Observable<Blob> {
    const query: string = [environment.apiUri, endPoint].join('');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .get<any>(query, { responseType: 'blob' as 'json' })
      .pipe(map((res) => new Blob([res], { type: 'application/pdf' })));
  }

  private handleError<T>(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.warn(error);
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      // this.matSnackBar.openFromComponent(BackendErrorComponent, {
      //   duration: 3000,
      //   data: error.error,
      // });
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(() => error);
    // return throwError(() =>
    //   Object.entries(error.error).map((x: [string, any]) => x.join(' : '))
    // );
  }
}
