import { ListboxValueChangeEvent } from '@angular/cdk/listbox';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { GlobalStateService } from 'src/app/core/state/global-state.service';
import { CartForm } from 'src/app/models/entities/quart-item.entity';
import { TypedFormControl, TypedFormGroup } from 'src/app/models/froms-typed';
import {
  AppearanceVariant,
  DimensionVariant,
  DimensionVariantSpecification,
  Product,
} from 'src/app/models/product.models';
import { Currency, PriceFactor } from 'src/app/models/shared.models';
import { QuartFormService } from 'src/app/product/services/forms/quart-form.service';

@Component({
  selector: 'app-quart-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './quart-dialog.component.html',
  styleUrls: ['./quart-dialog.component.scss'],
})
export class QuartDialogComponent {
  product: Product;
  quartForm: TypedFormGroup<CartForm>;
  currency$: Observable<Currency>;
  totalPrice$: Observable<number>;
  priceFactor$ = this.gss.priceFactor$;
  index: number;
  initAppearance = [
    this.data.initAppearance ?? this.data.product.appearanceVariants[0],
  ];
  selectedAppearanceVariantSubject = new BehaviorSubject<
    AppearanceVariant | undefined
  >(this.initAppearance[0]);

  messageMapping: { [k: string]: string } = {
    '=0': 'No look',
    '=1': 'One look',
    other: '# looks',
  };
  constructor(
    private gaService: GoogleAnalyticsService,
    private gss: GlobalStateService,
    private qfs: QuartFormService,
    public dialogRef: MatDialogRef<QuartDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      product: Product;
      initAppearance?: AppearanceVariant;
    }
  ) {
    this.currency$ = this.gss.userPaimentCurrency;
    this.quartForm = this.qfs.quartForm(data.product);
    this.product = data.product;

    this.totalPrice$ = this.quartForm.valueChanges.pipe(
      startWith(this.quartForm.value),
      withLatestFrom(this.gss.priceFactor$),
      map(([value, priceFactor]: [CartForm, PriceFactor]) =>
        priceFactor.getCartPrice(value)
      )
    );
  }
  getVariantCount(varId: number): number {
    return Object.keys(this.quartForm.value[varId]).reduce(
      (acc: number, current: string) =>
        this.quartForm.value[varId][parseInt(current)] + acc,
      0
    );
  }

  updateAppearance(event: ListboxValueChangeEvent<AppearanceVariant>): void {
    this.selectedAppearanceVariantSubject.next(undefined);
    setTimeout(() =>
      this.selectedAppearanceVariantSubject.next(event.value[0])
    );
  }

  compareAppearance = (a: AppearanceVariant, b: AppearanceVariant) =>
    a?.id === b?.id;

  getVariantControl(id: number): TypedFormControl<number> {
    return this.quartForm.get(
      `${this.selectedAppearanceVariantSubject.getValue().id}.${id}`
    ) as TypedFormControl<number>;
  }

  getDimensionString(dimensionVariant: DimensionVariant): string {
    return dimensionVariant.dimensionVariantsSpecs.reduce(
      (acc: string, cur: DimensionVariantSpecification, index: number) =>
        `${acc}${index === 0 ? '' : '/'}${cur.value} ${cur.measureType.unit}`,
      ''
    );
  }

  submit(): void {
    this.dialogRef.close(this.quartForm.value);
  }
}
