import { KeysMatching } from 'src/app/utils/utils';

export abstract class Entity<T = any> {
  id: number | string;
  toString?: () => string;

  constructor(args: Partial<T>) {
    Object.assign(this, args);
  }
}
export interface EntityConfig<T = any, F = any, A = any> {
  feature: string;
  baseUri: string;
  messages?: Partial<{
    title: Partial<Record<'new' | 'edit', string>>;
    action: Partial<
      Record<
        'created' | 'deleted' | 'updated',
        Partial<{
          buttonLabel: string;
          success: string;
          fail: string;
          confirmation: string;
        }>
      >
    >;
  }>;
  serializerFun: (arg: Partial<F>) => any;
  deserializerFun: (arg: A) => T;
}
export type ReadOnlyEntityConfig<T = any, F = any, A = any> = Omit<
  EntityConfig<T, F, A>,
  'serializerFun'
>;

export class EntityFilter<
  T extends Entity<T>,
  C extends Entity<T> = any,
  K extends KeysMatching<T, C> = any
> {
  selected: C[] = [];
  prop: K;
  filterFun: (obj: T) => boolean;
  constructor(selected: C[], prop: K, filterFun?: (obj: T) => boolean) {
    this.selected = selected;
    this.prop = prop;
    this.filterFun = filterFun
      ? filterFun
      : (obj) => this.selected.map((el) => el.id).includes(obj.id);
  }
}
