import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { GlobalStateService } from '../core/state/global-state.service';
import { Country, Currency, UserLanguage } from '../models/shared.models';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  NO_LOCALE_URI_CHUNKS = [
    'locale/countrys',
    'locale/currencys',
    'account/profiles/',
    'token',
    'execute-api.eu-central-1.amazonaws.com',
    'media.smcuivre.com',
  ];

  constructor(private gss: GlobalStateService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const SHIPPING_COUNTRY_HEADER = 'user-shipping-country';
    const USER_PAYMENT_CURRENCY_HEADER = 'user-payment-currency';
    return this.NO_LOCALE_URI_CHUNKS.some((dns) => request.url.includes(dns))
      ? next.handle(request)
      : this.gss.selectedLanguage.pipe(
          withLatestFrom(
            this.gss.userPaimentCurrency,
            this.gss.userShippingCountry
          ),
          mergeMap(
            ([userLang, payment_currency, shippingCountry]: [
              UserLanguage,
              Currency,
              Country
            ]) =>
              next.handle(
                request.clone({
                  setHeaders: {
                    'Accept-Language': userLang.id,
                    [USER_PAYMENT_CURRENCY_HEADER.toLowerCase()]:
                      payment_currency.alphaCode.toUpperCase(),
                    [SHIPPING_COUNTRY_HEADER.toLowerCase()]:
                      shippingCountry.alpha2Code.toUpperCase(),
                  },
                })
              )
          )
        );
  }
}
