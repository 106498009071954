import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

const appRoutes: Routes = [
  {
    path: 'product',
    loadChildren: async () => {
      const productModule = await import('./product/product.module');
      return productModule.ProductModule;
    },
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'miscellaneous',
    loadChildren: () =>
      import('./miscellaneous/miscellaneous.module').then(
        (m) => m.MiscellaneousModule
      ),
  },
  { path: '**', redirectTo: '/miscellaneous/home', pathMatch: 'full' },
];
const routerOptions: ExtraOptions = {
  enableTracing: true,
  scrollPositionRestoration: 'top',
  preloadingStrategy: PreloadAllModules,
};
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
