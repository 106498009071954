import { Entity, EntityConfig } from 'src/app/models/base/entity.base';

export interface SiteContentApi {
  id: number;
  key: string;
  content: string;
  description: string;
}
export interface SiteContent extends Omit<SiteContentApi, 'id'> {}
export class SiteContent extends Entity<SiteContent> {
  static deserialize = (arg: SiteContentApi): SiteContent =>
    new SiteContent(arg);
  static serialize = (arg: any): any => ({});
  toString = (): string => `${this.key}`;
}

export const siteContentConfig: EntityConfig<SiteContent, any, SiteContentApi> =
  {
    baseUri: 'locale/sitecontents/',
    feature: 'Site Content',
    serializerFun: SiteContent.serialize,
    deserializerFun: SiteContent.deserialize,
  };
