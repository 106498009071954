import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { ShopDialogService } from 'src/app/core/services/shop-dialog.service';
import { Country, Currency, UserLanguage } from 'src/app/models/shared.models';
import { GlobalStateService } from '../../../core/state/global-state.service';
import { DialogManagerService } from '../../services/dialog-manager.service';

@Component({
  selector: 'app-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss'],
})
export class ProductMenuComponent {
  @Output()
  sideNavEmmiter = new EventEmitter<any>();
  searchControl = new UntypedFormControl('');
  isSearchActive = false;
  isHomeRoute$: Observable<boolean>;
  user$: Observable<User>;
  languageList: UserLanguage[];
  shippingCountry$: Observable<Country>;
  payment_currency$: Observable<Currency>;
  profileRoutes: any;
  isWeb$ = this.breakpointObserver
    .observe([Breakpoints.Web])
    .pipe(map((x) => x.matches));
  isMedium$ = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.Medium])
    .pipe(map((x) => x.matches));
  isSmall$ = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(map((x) => x.matches));
  shopDialogHandler = this.sds;
  isLogged$ = this.as.isAuthenticated$;
  constructor(
    private sds: ShopDialogService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private as: AuthService,
    private dms: DialogManagerService,
    private gss: GlobalStateService
  ) {
    this.payment_currency$ = this.gss.userPaimentCurrency;
    this.shippingCountry$ = this.gss.userShippingCountry;
    this.user$ = this.as.user$;
    this.searchControl.valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        filter((term: string) => term !== '' && term.length > 2)
      )
      .subscribe((term) => {
        const navExtra: NavigationExtras = {
          queryParams: {
            search: term,
          },
        };
        this.router.navigate(['/product/list'], navExtra);
        if (!this.router.url.includes('product/list')) {
          this.router.navigate(['product/list']);
        }
      });
  }
  toggleSideNav() {
    this.sideNavEmmiter.emit('');
  }
  openShippingCoutryDialog() {
    this.dms.openLocaleSettingsDialog();
  }
  openPaimentCurrencyDialog() {
    this.dms.openLocaleSettingsDialog();
  }
  toggleSearch(): void {
    this.isSearchActive = !this.isSearchActive;
  }
}
