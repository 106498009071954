import { Entity, ReadOnlyEntityConfig } from 'src/app/models/base/entity.base';

export interface CountryApi {
  id: number;
  designation: string;
  alpha_2_code: string;
  alpha_3_code: string;
  num_code: string;
  currency: string;
  tax_rate: number;
}
export interface Country {
  id: number;
  designation: string;
  alpha2Code: string;
  alpha3Code: string;
  numCode: string;
  currency: string;
  tax_rate: number;
}
export class Country extends Entity<Country> {
  static deserialize = (arg: CountryApi): Country =>
    new Country({
      id: arg.id,
      designation: arg.designation,
      alpha2Code: arg.alpha_2_code,
      alpha3Code: arg.alpha_3_code,
      numCode: arg.num_code,
      currency: arg.currency,
      tax_rate: arg.tax_rate,
    });
  toString = (): string => `${this.designation}`;
}

export const countryConfig: ReadOnlyEntityConfig<Country, CountryApi> = {
  baseUri: 'locale/countrys/',
  feature: $localize`country`,
  deserializerFun: Country.deserialize,
};
