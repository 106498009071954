import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SiteContentStateService } from 'src/app/core/state/site-content-state.service';

@Directive({
  selector: '[appSiteContentInjector]',
})
export class SiteContentInjectorDirective implements AfterViewInit, OnDestroy {
  @Input() appSiteContentInjector: string;
  private subscription?: Subscription;
  constructor(
    private ref: ElementRef<Element>,
    private scss: SiteContentStateService
  ) {}
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.subscription = this.scss.entities
      .pipe(
        map((entities) =>
          entities.find(
            (entity) => entity?.key === this.appSiteContentInjector.trim()
          )
        ),
        filter((x) => (x ? true : false))
      )
      .subscribe((x) => {
        this.ref.nativeElement.textContent = x.content;
      });
  }
}
