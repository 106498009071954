<div class="text-black" mat-dialog-content>

    <p class="mb-4" i18n>Prices depend on</p>
    <ul class="list-disc pl-6">
        <li i18n>shipping country <span (click)="openShippingCoutryDialog()" class="ml-2 anchor">{{ (shippingCountry$
                |async)?.designation | uppercase
                }}</span></li>
        <li i18n>payment currency <span (click)="openPaimentCurrencyDialog()" class="anchor">{{ (payment_currency$ |
                async)?.alphaCode | uppercase
                }}</span></li>
    </ul>

</div>
<footer class="flex justify-end" mat-dialog-actions>
    <button mat-dialog-close mat-button><span class="uppercase" color="accent" i18n>got it</span></button>
</footer>