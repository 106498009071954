import {
  IMAGE_LOADER,
  ImageLoaderConfig,
  Location,
  PlatformLocation,
} from '@angular/common';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { CustomErrorHandlerService } from 'src/app/core/services/custom-error-handler.service';
import { environment } from 'src/environments/environment';
import { ConfigService } from './core/services/config.service';

//const init = (configService: ConfigService): () => Observable<any> => () => configService.init();

const getBaseUrl = (s: PlatformLocation): string => {
  const baseUrl = Location.stripTrailingSlash(s.getBaseHrefFromDOM());
  return baseUrl.length > 2 ? baseUrl : '/en-US';
};

export const providers: Array<any> = [
  {
    provide: ErrorHandler,
    useClass: environment.awsRum ? CustomErrorHandlerService : ErrorHandler,
  },
  //{ provide: ErrorHandler, useClass: CustomErrorHandlerService },
  {
    provide: APP_INITIALIZER,
    useFactory:
      (configService: ConfigService): (() => Observable<any>) =>
      () =>
        configService.init(),
    deps: [ConfigService],
    multi: true,
  },
  {
    provide: 'APP_BASE_HREF',
    useFactory: getBaseUrl,
    deps: [PlatformLocation],
  },
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: { duration: 5000 },
  },
  MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
  {
    provide: IMAGE_LOADER,
    useValue: (config: ImageLoaderConfig) => {
      return `${config.src}?format=auto&width=${config.width}`;
    },
  },
];
