<h2 matDialogTitle class="flex items-center gap-4 py-4">
  <mat-icon>settings</mat-icon>
  <span i18n class="first-letter:uppercase">your preferences</span>
  <button class="ml-auto" mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</h2>
<main class="flex flex-col" [formGroup]="preferenceForm" matDialogContent>
  <div class="text-gray-800 rounded-md p-4 bg-gray-200 flex gap-4 items-center mb-4">
    <mat-icon class="flex-1">report</mat-icon>
    <span i18n>Products prices depend on the <strong>shipping country</strong> taxes and
      fees and <strong>payment currency</strong>.
    </span>
  </div>
  <mat-form-field class="my-6 w-full">
    <mat-label i18n>shipping country</mat-label>
    <mat-select formControlName="shippingCountry">
      <mat-option *ngFor="let country of countries$ | async" [value]="country.alpha2Code">
        <span class="mat-subtitle-2">
          {{ country?.designation }}
        </span>
      </mat-option>
    </mat-select>
    <mat-icon class="mr-4" matPrefix>local_shipping</mat-icon>
    <mat-hint i18n class="first-letter:uppercase">products prices greatly depend on the shipping country taxes and
      fees.
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="my-6 w-full">
    <mat-label i18n>payment currency</mat-label>
    <mat-select formControlName="payment_currency">
      <mat-option *ngFor="let currency of currencies$ | async" [value]="currency.alphaCode">
        <span class="text-lg">
          {{ currency?.designation }}
        </span>
      </mat-option>
    </mat-select>
    <mat-icon class="mr-4" matPrefix>payments</mat-icon>
    <mat-hint i18n class="first-letter:uppercase">paiment currency have little or no impact on the products prices as we
      regulary update our internal exchange rates.</mat-hint>
  </mat-form-field>
</main>