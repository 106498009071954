import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataManagerService } from 'src/app/core/services/data-manager.service';
import { EntityStore } from 'src/app/core/services/entity-store.base';
import { CartItemService } from 'src/app/core/state/cart-item.service';
import { GlobalStateService } from 'src/app/core/state/global-state.service';
import {
  cartItemConfig,
  CartItem,
} from 'src/app/models/entities/quart-item.entity';
import {
  DimensionVariant,
  DimensionVariantSpecification,
} from 'src/app/models/product.models';
import { Currency } from 'src/app/models/shared.models';
import { ENTITY_CONFIG } from 'src/app/models/tokens';

@Component({
  selector: 'app-cart-item-list-dialog',
  templateUrl: './cart-item-list-dialog.component.html',
  styleUrls: ['./cart-item-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: ENTITY_CONFIG, useValue: cartItemConfig },
    { provide: EntityStore, useExisting: CartItemService },
    DataManagerService,
  ],
})
export class CartItemListDialogComponent {
  quartItems$: Observable<CartItem[]>;
  currency$: Observable<Currency>;
  totalPrice$: Observable<number>;
  totalQuantity$: Observable<number>;
  isNotSmall$ = this.breakpintObserver
    .observe('(min-width: 400px)')
    .pipe(map((x) => x.matches));
  messageMapping: { [k: string]: string } = {
    '=0': 'No item.',
    '=1': 'One item.',
    other: '# Items.',
  };
  constructor(
    private dms: DataManagerService,
    private breakpintObserver: BreakpointObserver,
    private gss: GlobalStateService,
    private qis: CartItemService
  ) {
    this.quartItems$ = this.qis.availableCartItems;
    this.currency$ = this.gss.userPaimentCurrency;
    this.totalPrice$ = this.qis.totalPrice;
    this.totalQuantity$ = this.qis.totalQuantity;
  }

  removeCartItem(quartItem: CartItem) {
    this.dms.delete(quartItem).subscribe(() => {});
  }

  getDimensionString(dimensionVariant: Partial<DimensionVariant>): string {
    return dimensionVariant.dimensionVariantsSpecs.reduce(
      (acc: string, cur: DimensionVariantSpecification, index: number) =>
        `${acc}${index === 0 ? '' : '/'}${cur.value} ${cur.measureType.unit}`,
      ''
    );
  }
}
