import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { PARAM_KEYS_MAP } from 'src/app/core/app.config';
import { AppDataService } from 'src/app/core/services/app-data.service';
import { MenuDataBuilderService } from 'src/app/core/services/menu-data-builder.service';
import {
  AdditionalFee,
  AdditionalFeeApi,
  additionalFeeConfig,
} from 'src/app/models/additional-fee.model';
import { Category, MenuTreeData } from 'src/app/models/product.models';
import {
  AppQueryParamKey,
  Country,
  Currency,
  PriceFactor,
  SessionStorageKey,
  UserLanguage,
} from 'src/app/models/shared.models';
import {
  ApiShippingVariant,
  ShippingVariant,
  shippingVariantConfg,
} from 'src/app/models/shipping-modality.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  private readonly countriesSubject = new BehaviorSubject<Country[]>([]);
  private readonly currenciesSubject = new BehaviorSubject<Currency[]>([]);
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);
  private readonly categoriesSubject = new BehaviorSubject<Category[]>([]);
  private readonly selectedLanguageSubject = new BehaviorSubject<UserLanguage>(
    null
  );

  priceFactor$ = combineLatest([this.as.user$, this.localeChanged]).pipe(
    debounceTime(500),
    switchMap(() => this.ads.get<PriceFactor>('product/price_factors')),
    map((x) => new PriceFactor(x)),
    shareReplay()
  );

  constructor(
    private as: AuthService,
    private ads: AppDataService,
    private mdbs: MenuDataBuilderService,
    private route: ActivatedRoute
  ) {
    this.route.queryParamMap
      .pipe(
        filter(
          (paramMap: ParamMap) =>
            paramMap.has(AppQueryParamKey.shippingCountry) ||
            paramMap.has(AppQueryParamKey.payment_currency)
        )
      )
      .subscribe((paramMap: ParamMap) => {
        if (paramMap.has(AppQueryParamKey.shippingCountry)) {
          sessionStorage.setItem(
            SessionStorageKey.shippingCountry,
            paramMap.get(AppQueryParamKey.shippingCountry)
          );
          this.countriesSubject.next(this.countriesSubject.getValue());
        }
        if (paramMap.has(AppQueryParamKey.payment_currency)) {
          sessionStorage.setItem(
            SessionStorageKey.payment_currency,
            paramMap.get(AppQueryParamKey.payment_currency)
          );
          this.currenciesSubject.next(this.currenciesSubject.getValue());
        }
      });
  }

  get selectedLanguage(): Observable<UserLanguage> {
    return this.selectedLanguageSubject.asObservable();
  }
  setSelectedLanguage(payload: UserLanguage): void {
    return this.selectedLanguageSubject.next(payload);
  }

  get categories(): Observable<Category[]> {
    return this.categoriesSubject.asObservable();
  }

  getBreadCrumb(catId?: number): Observable<Category[]> {
    return this.route.queryParamMap.pipe(
      map((paramMap) => paramMap.get(PARAM_KEYS_MAP.CAT_DESIGNATION)),
      withLatestFrom(this.categories),
      map(([value, categories]: [string, Category[]]) =>
        catId
          ? this.setItems(categories, this.getItem(categories, catId))
          : value
          ? this.setItems(
              categories,
              categories.find(
                (cat) => cat.designation.toLowerCase() === value.toLowerCase()
              )
            )
          : []
      ),
      map((breadcrumb) => breadcrumb.reverse())
    );
  }
  get activeCategory(): Observable<Category> {
    return this.route.queryParamMap.pipe(
      map((paramMap) => paramMap.get(PARAM_KEYS_MAP.CAT_DESIGNATION)),
      withLatestFrom(this.categories),
      map(([value, categories]: [string, Category[]]) =>
        value !== '' && value
          ? categories.filter((cat) => cat.designation === value).shift()
          : {
              designation: 'All Cateogries',
              isRoot: true,
              children: categories.filter((cat) => cat.isRoot),
            }
      )
    );
  }

  setCategories(payload: Category[]): void {
    this.categoriesSubject.next(payload);
  }

  get navMenuTree(): Observable<MenuTreeData[]> {
    return this.categories.pipe(
      map((categories) => this.mdbs.buildMenuTree(categories))
    );
  }

  get isLoading(): Observable<boolean> {
    return this.ads.apiLoading;
  }
  setIsLoading(payload: boolean): void {
    this.isLoadingSubject.next(payload);
  }

  initUserLocales(countryCode: string): void {
    sessionStorage.setItem(SessionStorageKey.shippingCountry, countryCode);
    let relatedCurrency =
      this.countriesSubject
        .getValue()
        .find((country: Country) => country.alpha2Code === countryCode)
        ?.currency ?? 'USD';
    relatedCurrency = this.currenciesSubject
      .getValue()
      .find((currency) => currency.alphaCode === relatedCurrency)
      ? relatedCurrency
      : 'USD';
    sessionStorage.setItem(SessionStorageKey.payment_currency, relatedCurrency);
    this.currenciesSubject.next(this.currenciesSubject.getValue());
    this.countriesSubject.next(this.countriesSubject.getValue());
  }
  setCurrencies(payload: Currency[]): void {
    this.currenciesSubject.next(payload);
  }
  get currencies(): Observable<Currency[]> {
    return this.currenciesSubject.asObservable();
  }
  setCountries(payload: Country[]): void {
    this.countriesSubject.next(payload);
  }
  get countries(): Observable<Country[]> {
    return this.countriesSubject.asObservable();
  }

  get userPaimentCurrency(): Observable<Currency> {
    return this.currencies.pipe(
      map((currencies: Currency[]) =>
        currencies.find(
          (currency) =>
            currency.alphaCode ===
            sessionStorage.getItem(SessionStorageKey.payment_currency)
        )
      ),
      filter((currency) => currency !== undefined)
    );
  }

  get userShippingCountry(): Observable<Country> {
    return this.countries.pipe(
      map((countries: Country[]) =>
        countries.find(
          (country) =>
            country.alpha2Code ===
            sessionStorage.getItem(SessionStorageKey.shippingCountry)
        )
      ),
      filter((country) => country !== undefined)
    );
  }

  private getItem = (
    categories: Category[],
    key: number | undefined
  ): Category | undefined => categories.find((category) => category.id === key);

  private setItems = (
    categories: Category[],
    param: Category | undefined
  ): Category[] => {
    if (param === undefined || param.designation === 'All Cateogries') {
      return [];
    } else {
      const cat = this.getItem(categories, param.id);
      const parentCategory = this.getItem(categories, param.parentCategory);
      return [param].concat(
        this.setItems(categories, cat?.isRoot ? undefined : parentCategory)
      );
    }
  };

  get localeChanged(): Observable<any> {
    return combineLatest([
      this.userPaimentCurrency,
      this.userShippingCountry,
    ]).pipe(debounceTime(200));
  }

  get shippingVariants(): Observable<ShippingVariant[]> {
    return this.localeChanged.pipe(
      debounceTime(500),
      switchMap(() =>
        this.ads.get<ApiShippingVariant[]>(shippingVariantConfg.baseUri)
      ),
      map((res) =>
        res.map((_var) => shippingVariantConfg.deserializerFun(_var))
      )
    );
  }
  get additional_fees(): Observable<AdditionalFee[]> {
    return this.localeChanged.pipe(
      debounceTime(500),
      switchMap(() =>
        this.ads.get<AdditionalFeeApi[]>(additionalFeeConfig.baseUri)
      ),
      map((res) => res.map((_var) => additionalFeeConfig.deserializerFun(_var)))
    );
  }
}
