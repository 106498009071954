import { Entity, ReadOnlyEntityConfig } from 'src/app/models/base/entity.base';

export interface CurrencyApi {
  id: number;
  designation: string;
  symbol: string;
  country: string;
  alpha_code: string;
  num_code: number;
}
export interface Currency {
  id: number;
  designation: string;
  symbol: string;
  country: string;
  alphaCode: string;
  numCode: number;
}
export class Currency extends Entity<Currency> {
  static deserialize = (arg: CurrencyApi): Currency =>
    new Currency({
      id: arg.id,
      designation: arg.designation,
      symbol: arg.symbol,
      country: arg.country,
      numCode: arg.num_code,
      alphaCode: arg.alpha_code,
    });
  toString = (): string => `${this.designation}`;
}

export const currencyConfig: ReadOnlyEntityConfig<Currency, CurrencyApi> = {
  baseUri: 'locale/currencys/',
  feature: $localize`currency`,
  deserializerFun: Currency.deserialize,
};
