import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth0/auth0-angular';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { CoreModule } from 'src/app/core/core.module';
import { materialProviders } from 'src/app/material.providers';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './http-interceptors';
import { PageNotFoundComponent } from './page-not-found/page-not-found/page-not-found.component';
import { providers } from './providers';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    CoreModule,
    NgOptimizedImage,
    AuthModule.forRoot(environment.auth0),
    ...(environment.ga
      ? [
          NgxGoogleAnalyticsModule.forRoot(environment.ga.trackingCode),
          NgxGoogleAnalyticsRouterModule.forRoot(environment.ga.routing),
        ]
      : []),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
  ],
  providers: [httpInterceptorProviders, providers, materialProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
