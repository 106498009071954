import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  typedFormControl,
  typedFormGroup,
  TypedFormGroup,
} from 'src/app/models/froms-typed';
import { Product } from 'src/app/models/product.models';
import {
  CartForm,
  CartItemForm,
  CartSub,
} from 'src/app/models/entities/quart-item.entity';

@Injectable({
  providedIn: 'root',
})
export class QuartFormService {
  constructor() {}

  quartItemForm = (): TypedFormGroup<CartItemForm> =>
    typedFormGroup<CartItemForm>({
      quantity: typedFormControl<number>(0, [Validators.required]),
      dimensionVariant: typedFormControl<number>(undefined, [
        Validators.required,
      ]),
      appearanceVariant: typedFormControl<number>(undefined, [
        Validators.required,
      ]),
    });

  quartForm = (product: Product): TypedFormGroup<CartForm> => {
    return typedFormGroup<CartForm>(
      Object.fromEntries(
        product.appearanceVariants.map((appVar) => [
          appVar.id,
          typedFormGroup<CartSub>(
            Object.fromEntries(
              product.dimensionVariants.map((dimVar) => [
                dimVar.id,
                typedFormControl<number>(0, [Validators.required]),
              ])
            )
          ),
        ])
      )
    );
  };
}
