import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { exhaustMap, first, map, switchMap, tap } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SiteContentStateService } from 'src/app/core/state/site-content-state.service';
import { CountryApi, countryConfig } from 'src/app/models/country.models';
import { CurrencyApi, currencyConfig } from 'src/app/models/currency.model';
import {
  ApiCategory,
  BaseImage,
  Category,
} from 'src/app/models/product.models';
import { Country, Currency, LanguageType } from 'src/app/models/shared.models';
import { environment } from 'src/environments/environment';
import { GlobalStateService } from '../state/global-state.service';
import { AppDataService } from './app-data.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(
    @Inject('APP_BASE_HREF') private baseUrl: string,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private ps: ProfileService,
    private http: HttpClient,
    private gss: GlobalStateService,
    private scss: SiteContentStateService,
    private ads: AppDataService
  ) {
    iconRegistry
      .addSvgIcon(
        'loading',
        sanitizer.bypassSecurityTrustResourceUrl(
          './assets/icons/loading_logo.svg'
        )
      )
      .addSvgIcon(
        'loading_2',
        sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/loader_2.svg')
      )
      .addSvgIcon(
        'logo_mini',
        sanitizer.bypassSecurityTrustResourceUrl(
          './assets/icons/logo_smc_mini.svg'
        )
      )
      .addSvgIcon(
        'logo_full_white',
        sanitizer.bypassSecurityTrustResourceUrl(
          './assets/icons/logo_full_white.svg'
        )
      )
      .addSvgIcon(
        LanguageType.GERMAN,
        sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/germany.svg')
      )
      .addSvgIcon(
        LanguageType.FRENCH,
        sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/france.svg')
      )
      .addSvgIcon(
        LanguageType.ENGLISH,
        sanitizer.bypassSecurityTrustResourceUrl(
          './assets/icons/united-kingdom.svg'
        )
      )
      .addSvgIcon(
        'landing-page-illustration',
        sanitizer.bypassSecurityTrustResourceUrl(
          './assets/icons/Illustration-landing-page.svg'
        )
      )
      .addSvgIcon(
        'facebook',
        sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/facebook.svg')
      )
      .addSvgIcon(
        'instagram',
        sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/insta.svg')
      )
      .addSvgIcon(
        'contact-us',
        sanitizer.bypassSecurityTrustResourceUrl(
          './assets/icons/contact_us_illustration.svg'
        )
      )
      .addSvgIcon(
        'logo_black_bg',
        sanitizer.bypassSecurityTrustResourceUrl(
          './assets/icons/logo_black_background.svg'
        )
      );
    const language = this.baseUrl.replace(/\//g, '');
    const selectedLanguage = environment.supportedLanguages.filter(
      (lang) => lang.id === language
    )[0];
    this.gss.setSelectedLanguage(selectedLanguage);
  }

  initSiteContent(): Observable<any> {
    return combineLatest([
      this.ads.get<ApiCategory[]>(`product/categories/`),
      this.ads.get<BaseImage[]>(`product/icons/`),
    ]).pipe(
      first(),
      tap((res: [ApiCategory[], BaseImage[]]) => {
        res[1].forEach((jsonItem) => {
          this.iconRegistry.addSvgIcon(
            jsonItem.designation,
            this.sanitizer.bypassSecurityTrustResourceUrl(jsonItem.content)
          );
        });
        const categories = res[0].map((cat) => new Category(cat));
        this.gss.setCategories(categories);
      }),
      exhaustMap(() => this.scss.fetchEntities())
    );
  }
  initLocales(): Observable<[Country[], Currency[]]> {
    return forkJoin([
      this.ads.get<CountryApi[]>(countryConfig.baseUri).pipe(
        map((countries) =>
          countries.map((country) => countryConfig.deserializerFun(country))
        ),
        tap((countries) => this.gss.setCountries(countries))
      ),
      this.ads.get<CurrencyApi[]>(currencyConfig.baseUri).pipe(
        map((currencies) =>
          currencies.map((country) => currencyConfig.deserializerFun(country))
        ),
        tap((currencies) => this.gss.setCurrencies(currencies))
      ),
    ]);
  }

  init(): Observable<any> {
    return forkJoin([
      this.initLocales(),
      this.http
        .get<{ country: string }>(
          'https://yhph57qw9k.execute-api.eu-central-1.amazonaws.com/dev/location-api'
        )
        .pipe(first()),
    ]).pipe(
      tap(([x, y]: [[Country[], Currency[]], { country: string }]) =>
        this.gss.initUserLocales(y.country)
      ),
      switchMap(() => this.initSiteContent()),
      switchMap(() => this.scss.fetchEntities())
    );
  }
}
