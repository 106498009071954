import { Entity, ReadOnlyEntityConfig } from 'src/app/models/base/entity.base';

export interface AdditionalFeeApi {
  id: number;
  amount: number;
  designation: string;
}
export interface AdditionalFee {
  id: number;
  amount: number;
  designation: string;
}
export class AdditionalFee extends Entity<AdditionalFee> {
  static deserialize = (arg: AdditionalFeeApi): AdditionalFee =>
    new AdditionalFee({
      id: arg.id,
      amount: arg.amount,
      designation: arg.designation,
    });
  toString = (): string => `${this.designation} , ${this.amount}`;
}

export const additionalFeeConfig: ReadOnlyEntityConfig<
  AdditionalFee,
  AdditionalFeeApi
> = {
  baseUri: 'locale/additionalfees/',
  feature: 'Additional Fee',
  deserializerFun: AdditionalFee.deserialize,
};
