import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocaleSettingsDialogComponent } from 'src/app/core/components/locale-settings-dialog/locale-settings-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogManagerService {
  constructor(private dialog: MatDialog) {}

  openLocaleSettingsDialog() {
    this.dialog.open(LocaleSettingsDialogComponent, {
      width: '800px',
      maxWidth: '100vw',
    });
  }
}
