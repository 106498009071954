import { Entity, EntityConfig } from 'src/app/models/base/entity.base';
import {
  ApiDimensionVariant,
  ApiProduct,
  AppearanceVariant,
  DimensionVariant,
  Product,
} from 'src/app/models/product.models';

export interface CartItemApi {
  id: number;
  quantity: number;
  current_unit_price?: number;
  dimensionVariant: Omit<ApiDimensionVariant, 'price'>;
  appearanceVariant: Omit<AppearanceVariant, 'price'>;
  created: string;
  product: Pick<ApiProduct, 'id' | 'designation'>;
  order_unit_price?: number;
  order?: number;
  account: number;
}
export interface CartItemApiWrite {
  quantity: number;
  dimensionVariant: number;
  appearanceVariant: number;
}
export interface CartItemForm {
  quantity: number;
  dimensionVariant: number;
  appearanceVariant: number;
}
export interface CartSub {
  [dimension: number]: number;
}
export interface CartForm {
  [appearanceVariant: number]: CartSub;
}

export interface CartItem {
  id: number;
  quantity: number;
  current_unit_price?: number;
  dimensionVariant: Omit<DimensionVariant, 'price'>;
  appearanceVariant: Omit<AppearanceVariant, 'price'>;
  created: string;
  product: Pick<Product, 'id' | 'designation'>;
  order_unit_price?: number;
  order?: number;
  account: number;
}

export class CartItem extends Entity<CartItem> {
  static deserialize = (obj: CartItemApi): CartItem =>
    new CartItem({
      ...obj,
      current_unit_price: obj.current_unit_price,
      product: Product.deserialize(obj.product) as Pick<
        Product,
        'id' | 'designation'
      >,
      dimensionVariant: DimensionVariant.deserialize(
        obj.dimensionVariant
      ) as DimensionVariant,
      appearanceVariant: obj.appearanceVariant,
      order: obj.order,
      order_unit_price: obj.order_unit_price,
      account: obj.account,
    });
  static serialize = (obj: CartForm): CartItemApiWrite[] =>
    Object.entries(obj).flatMap(([_appVar, _quartSub]: [string, CartSub]) =>
      Object.entries(_quartSub)
        .filter((_val: [string, number]) => _val[1] !== 0)
        .flatMap((_val: [string, number]) => ({
          quantity: _val[1],
          dimensionVariant: parseInt(_val[0]),
          appearanceVariant: parseInt(_appVar),
        }))
    );

  get currentPrice(): number | undefined {
    return this.current_unit_price * this.quantity;
  }

  get hasOrder(): boolean {
    return this.order ? true : false;
  }

  toString = (): string =>
    `${this.product.designation} ${this.appearanceVariant?.look?.material}/${this.dimensionVariant.designation} x ${this.quantity}`;
}

export const cartItemConfig: EntityConfig<CartItem, CartItemForm> = {
  baseUri: 'shop/cart-items/',
  feature: 'Cart Item',
  serializerFun: CartItem.serialize,
  deserializerFun: CartItem.deserialize,
};
